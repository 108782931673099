<template>
    <v-container fluid>
        <tool-bar :toolbar="toolbar" :search="search" @insertDialog="openDialog()"></tool-bar>
        <v-data-table
            :headers="leadTimeHeader"
            :height="tableHeight"
            :items="displayLeadTimeData"
            :search="search"
            fixed-header
        >
            <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">{{item.additional_industry_id}}</td>
                    <td class="text-center">{{item.lead_day + ' day / s'}}</td>
                    <td class="text-center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-on="on" v-bind="attrs" icon @click="EditDialog(item)">
                                    <v-icon class="edit-file-svg"></v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Data</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-on="on" v-bind="attrs" icon @click="Delete(item)">
                                    <v-icon class="delete-file-svg"></v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>


        <v-row justify-center>
            <v-dialog
                v-model="insertDialog"
                persistent
                width="450"
                @keydown.esc="insertDialog = false"
            >
                <v-form
                    id="Insert"
                    ref="Insert"
                    @submit.prevent="Insert"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline">{{ 'Add Lead Days' }}</span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="insertDialog = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" style="margin-bottom: -18px;">
                                        <v-autocomplete
                                            v-model="add.category_id"
                                            :label="$t('industryHeader.additionalOrderingIndustry')"
                                            :items="filteredIndustryData"
                                            :item-text="item => `( ${ item.additional_industry_id } ) ${item.product_industry_name}`"
                                            item-value="additional_industry_id"
                                            outlined
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            required
                                            persistent-placeholder
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="add.lead_days"
                                            label="Lead Days"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            persistent-placeholder
                                            @keypress="onlyNumber($event)"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" :disabled="isSubmitted" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>

        <v-row justify-center>
            <v-dialog
                v-model="editDialog"
                persistent
                width="450"
                @keydown.esc="editDialog = false"
            >
            <v-card>
                <v-card-title class="card-title">
                    <span class="title-headline">{{ 'Edit Lead Days' }}</span>
                    <v-spacer></v-spacer>
                    <v-icon color="white" @click="editDialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="card-text">
                    <v-container>
                        <v-row class="mt-1">
                            <v-col cols="12" style="margin-bottom: -18px;">
                                <v-autocomplete
                                    v-model="edit.category_id"
                                    :label="$t('industryHeader.additionalOrderingIndustry')"
                                    :items="filteredIndustryData"
                                    :item-text="item => `( ${ item.additional_industry_id } ) ${item.product_industry_name}`"
                                    item-value="additional_industry_id"
                                    outlined
                                    dense
                                    class="mb-2 required"
                                    :rules="rules.required"
                                    required
                                    persistent-placeholder
                                    readonly
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="edit.lead_days"
                                    label="Lead Days"
                                    outlined
                                    required
                                    dense
                                    class="mb-2 required"
                                    :rules="rules.required"
                                    persistent-placeholder
                                    @keypress="onlyNumber($event)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="Edit()" :disabled="isSubmitted" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import swal from 'sweetalert2';

import Toolbar from '../includes/Toolbar.vue'

export default {
    components: {
        "tool-bar": Toolbar,
    },
    data: () => ({
        toolbar: {
            title: 'toolbar.homeTitle',
            sub_title: 'navigation.minimalLeadTime',
            hasSearch: true,
            insertDialog: true,

        },
        tableHeight: window.innerHeight - 210,
        insertDialog: false,
        add: {},
        isSubmitted: false,
        editDialog: false,
        edit: {},
    }),
    computed: {
        ...mapState(['leadTimeData', 'search', 'rules', 'industryData', 'loggedInUser']),
        leadTimeHeader(){
            return [
                {
                    text: this.$t('homeHeader.categoryId'),
                    align: 'center',
                    value: 'additional_industry_id',
                    class: 'white--text title'
                },
                {
                    text: 'Lead Days',
                    align: 'center',
                    value: '',
                    class: 'white--text title'
                },
                {
                    text: this.$t('global.action'),
                    align: 'center',
                    value: 'actions',
                    class: 'white--text title'
                }
            ]
        },
        displayLeadTimeData(){
            return this.leadTimeData;
        },
        filteredIndustryData(){
            const isExisted = this.leadTimeData.map(m => m.additional_industry_id);
            const filteredData = this.industryData.map(m => ({
                additional_industry_id: m.additional_industry_id,
                product_industry_name: m.product_industry_name,
                disabled: isExisted.includes(m.additional_industry_id)
            }))
            return filteredData;
        },
    },
    methods: {
        ...mapActions(['getLeadTime', 'loadCategoryData']),
        openDialog(){
            this.insertDialog = true;
        },
        onlyNumber($e) {
            let keyCode = $e.keyCode ? $e.keyCode : $e.which;
            if( ( keyCode < 48 || keyCode > 57 ) && keyCode !== 46 ) {
                $e.preventDefault();
            }
        },
        Insert(){
            if(this.$refs.Insert.validate()) {
                swal
                    .fire({
                        title: "Confirmation",
                        text: `Are you sure to add this record?`,
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Confirm`,
                        denyButtonText: `Cancel`,
                }).then(result => {
                    if(result.isConfirmed) {
                        this.add.created_by = this.loggedInUser.user_id;
                        axios({
                            method: 'post',
                            url: `${window.api}/mysql/insertLeadTime`,
                            data: this.add,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }).then(res => {
                            if(res.data) {
                                swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: `Successfully added data`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                }).then(() => {
                                    this.insertDialog = false;
                                    this.add = {};
                                    this.getLeadTime();
                                });
                            }
                        })
                    }
                })
            }
        },
        EditDialog(data){
            this.edit.category_id = data.additional_industry_id;
            this.edit.lead_days = data.lead_day
            this.edit.id = data.id
            this.editDialog = true;
        },
        Edit(){
            swal.fire({
                text: "Are you sure you want to edit this data?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(result => {
                if(result.isConfirmed) {
                    this.edit.updated_by = this.loggedInUser.user_id;
                    axios({
                        method: 'post',
                        url: `${window.api}/mysql/updateLeadTime`,
                        data: this.edit,
                        headers: {
                            'x-api-key' : process.env.VUE_APP_HEADERS
                        }
                    }).then(res => {
                        if(res.data) {
                            swal.fire({
                                position: "center",
                                icon: "success",
                                title: `You have successfully edit data`,
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(() => {
                                this.editDialog = false;
                                this.edit = {};
                                this.getLeadTime();
                            });
                        }
                    })
                }
            })
        },
        Delete(data){
            swal.fire({
                title: "Confirmation",
                text: `Are you sure to delete this record?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Confirm`,
                denyButtonText: `Cancel`,
            }).then(result => {
                if(result.isConfirmed) {
                    axios({
                        method: 'post',
                        url: `${window.api}/mysql/deleteLeadTime`,
                        data: data,
                        headers: {
                            'x-api-key' : process.env.VUE_APP_HEADERS
                        }
                    }).then(res => {
                        if(res.data) {
                            swal.fire({
                                position: "center",
                                icon: "success",
                                title: "Your data has beed deleted",
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(() => {
                                this.getLeadTime();
                            });
                        }
                    })
                }
            })
        },
    },
    watch: {
        insertDialog(val) {
            if(!val) {
                this.$refs.Insert.reset();
            }
        }
    },
    mounted() {
        this.$store.state.search = '';

        this.getLeadTime().then(() => {
            this.loadCategoryData();
        });

    },
}
</script>
<style scoped>
.v-data-table ::v-deep th {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}
</style>
