import { render, staticRenderFns } from "./MinimalLeadTime.vue?vue&type=template&id=2c5e5274&scoped=true&"
import script from "./MinimalLeadTime.vue?vue&type=script&lang=js&"
export * from "./MinimalLeadTime.vue?vue&type=script&lang=js&"
import style0 from "./MinimalLeadTime.vue?vue&type=style&index=0&id=2c5e5274&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5e5274",
  null
  
)

export default component.exports